<template>
  <v-dialog
    class="custom-dialog"
    :showConfirmButton="false"
    :showCancelButton="false"
    v-model="isShowCustomDialog"
  >
    <div class="dialog-header">
      <div class="title">{{ title }}</div>
      <img @click="closeDialog" src="./img/close.png" alt="" />
    </div>
    <div class="dialog-content">
      <slot></slot>
    </div>
    <div class="dialog-footer" v-if="isShowFooter">
      <div class="cancel-btn plain" @click="cancelDialog">{{ cancelText }}</div>
      <div class="confirm-btn" @click="confirmDialog">{{ confirmText }}</div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "custom-dialog",
  components: {},
  props: {
    //标题
    title: {
      type: String,
      default: "",
    },
    //确认文字
    confirmText: {
      type: String,
      default: "提交",
    },
    //取消文字
    cancelText: {
      type: String,
      default: "取消",
    },
    value: {
      type: Boolean,
      default: false,
    },
    isShowFooter: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    isShowCustomDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  created() {},
  mounted() {},
  methods: {
    //确认
    confirmDialog() {
      this.$emit("confirm");
    },
    //取消
    cancelDialog() {
      this.isShowCustomDialog = false;
    },
    //关闭
    closeDialog() {
      this.isShowCustomDialog = false;
    },
  },
};
</script>

<style scoped lang="less">
.custom-dialog {
  ::v-deep .van-dialog {
    width: 633px;
    padding: 37px 38px 48px 36px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .dialog-header {
      display: flex;
      align-items: center;
      .title {
        flex: 1;
        font-weight: 600;
        font-size: 18px;
        color: #000000;
        line-height: 25px;
        font-style: normal;
      }
      img {
        width: 16px;
        height: 17px;
        object-fit: cover;
      }
    }
    .dialog-content {
      min-height: 100px;
      flex: 1;
    }
    .dialog-footer {
      display: flex;
      justify-content: center;
      .confirm-btn {
        cursor: pointer;
        box-sizing: border-box;
        padding: 13px 76px;
        background: #1874fd;
        border-radius: 8px;
        font-weight: 600;
        font-size: 20px;
        color: #ffffff;
        line-height: 28px;
        text-align: left;
        font-style: normal;
      }
      .plain {
        box-sizing: border-box;
        padding: 13px 76px;
        
        border-radius: 8px;
        font-weight: 600;
        font-size: 20px;
        color: #ffffff;
        line-height: 28px;
        text-align: left;
        font-style: normal;
        color: #1874fd;
        border: 1px solid #1874fd;
        background: #ffff;
        margin-right: 129px;
      }
    }
  }
}
</style>
